

export const eligibilty = [{
  head: 'Education',
  desc: 'We encourage students from all educational fields to apply',
  img: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ugczsm.png"

},{
  head: 'Professional Experience',
  desc: '0-4 years of experience is ideal but not a necessity',
  img: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ullzi6.png"
},{
  head: 'Intent',
  desc: 'Deep interest & intent in building a business & sales career',
  img: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ukss22.png"
}];