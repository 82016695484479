import React from "react";
import "./styles.scss";
import PlacementProcess from "./PlacementProcess";

const Certification = () => {
  return (
    <div className="career-prep " >
      <div className="career-title">80+ hours of career prep</div>
      <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2aafvlc.png" loading="lazy" className="c-p-underline" />
      <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1uftzy4.png" loading="lazy" className="careep-prep-img mobile-none" />
      <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ufzs3r.png" loading="lazy" className="careep-prep-img pc-none" />
      <PlacementProcess />
     
    </div>
  );
};

export default Certification;
