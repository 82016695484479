import React from "react";
import Slider from "react-slick";
import { newsData } from "./data";
import "./styles.scss";



function AlumniCarousal() {

  const settings = {
    prevArrow: "",
    dots: false,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 4000,
    fadeIn: false,
    autoplay: true,
    pauseOnHover: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 805,
        settings: {
          slidesToShow: 2,
          dots: true,
          variableWidth: false,
          centerMode: false,
          arrows: true,
        },
      },
    ],
  };

  return (
    <React.Fragment>
      {/* <Alumni data={cardData} /> */}
      <div className="mlp-main-placements mlp-main basl-main-placements news">
        <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1uky8b9.png" loading="lazy" className="rightDot" />
        <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ukwnn2.png" loading="lazy" className="leftDot" />
        <div className="placements-container basl-placement-container">
          <div className="bsl-alumni-header">
            <h2 className="main-header">
              In the News
            </h2>
            <div className="new-underline-new">
              <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2aafvlc.png" loading="lazy" className="news-underline" />
            </div>
          </div>
          <div className="placement-slider basl-alumni-slider sales-news-card-wrapper">
            <Slider {...settings}>
              {newsData?.map((news, key) => (
                <a href={news.link} target="_blank" key={key} className="news-card-basl">
                  <img className="news-card-basl-img" src={news.image} loading="lazy" />
                  <div className="news-card-basl-footer">
                    <img className="news-card-comp" src={news.compImage} loading="lazy" />
                    <div className="news-basl-footer-text mobile-hide">{news.desc} {news.readMore && <span className="news-card-comp-yello">...Read More</span>}</div>
                    <div className="news-basl-footer-text pc-hide">{news.descShort} {news.readMore && <span className="news-card-comp-yello">...Read More</span>}</div></div>
                </a>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AlumniCarousal;
