import React from "react";


const BenifitsTable = ({ tableName, setTable }) => {
  return (
    <div className="table-section">
      {tableName === "roi" && (
        <table className="roi">
          <tr>
            <th></th>
            <th>
              {/* <img
                className="basl_logo"
                src={bslpLogo}
                loading="lazy"
              /> */}
              <img
                className="basl_logo"
                src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1uladbr.png"
              />
            </th>
            <th>
              MBA
              <br /> (beyond top 20)
            </th>
            <th>Online Business Courses / Degrees</th>
          </tr>
          <tr>
            <td>Time Investment</td>
            <td>9 Months</td>
            <td>2 years</td>
            <td>7-12 Months</td>
          </tr>
          <tr>
            <td>Core Benefit</td>
            <td>
            ₹7.5-17L jobs + <br/>
            Certification + Network 
            </td>
            <td>
              Degree + <br /> ₹6-13L Jobs
            </td>
            <td>Certifications</td>
          </tr>
          <tr>
            <td>Fee</td>
            <td>₹1.85L</td>
            <td>₹6-15L</td>
            <td>₹3-7L</td>
          </tr>
          <tr>
            <td>Fee if no job post completion</td>
            <td className="desk cell-hl">60% fee refunded if job &lt; 7.5L</td>
            <td className="mob cell-hl">60% fee refunded<br className="pc-hide"/> if job &lt; 7.5L</td>
            <td>No</td>
            <td>No</td>
          </tr>
          <tr>
            <td>Cost of travel</td>
            <td className="borderBtm">No Cost as <br/>
            program is online</td>
            <td className="desk">
            Full-Time Offline, <br/>needs relocation
            </td>
            <td className="mob">
            Full-Time Offline, <br/>needs relocation
            </td>
            <td>No</td>
          </tr>
          
        </table>
      )}
      {tableName === "placements" && (
        <table className="placements">
          <tr>
            <th></th>
            <th>
              <img
                className="basl_logo"
                src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1uladbr.png"
                loading="lazy"
              />
            </th>
            <th>
              MBA
              <br /> (beyond top 20)
            </th>
            <th>Online Business Courses / Degrees</th>
          </tr>
          <tr>
            <td>Average CTC</td>
            <td>₹11.2L</td>
            <td>₹7L</td>
            <td>Not defined</td>
          </tr>
          <tr>
            <td>Minimum CTC</td>
            <td>₹7.5L</td>
            <td>Not defined</td>
            <td>Not defined</td>
          </tr>
          <tr>
            <td>Highest CTC</td>
            <td>₹17.2L</td>
            <td>₹15L</td>
            <td>Not defined</td>
          </tr>
          <tr>
            <td>Placement Reports</td>
            <td>
              Published for all programs.
              <br />{" "}
              <a
                href="https://placement-reports.kraftshala.com/"
                target="_blank"
              >
                {" "}
                See Here
              </a>
            </td>
            <td>Partially</td>
            <td>No</td>
          </tr>
          <tr>
            <td>
              No. of Job
              <br /> Interviews
            </td>
            <td className="desk cell-hl">
              No upper cap - We keep going
              <br /> till you’re placed
            </td>
            <td className="mob cell-hl">
              No upper cap - We keep <br />
              going till you’re placed
            </td>
            <td>Nothing specified</td>
            <td>Nothing specified</td>
          </tr>
          <tr>
            <td>Kinds of Roles</td>
            <td className="borderBtm extra-bold">
            SaaS & FMCG Sales<br/> Roles in top-tier <br/>companies
            </td>
            <td>
              <b>95% Sales roles</b>
              <br /> 5% Marketing / Ops / Fin
            </td>
            <td>Nothing specified</td>
          </tr>
        </table>
      )}
      {tableName === "community" && (
        <table className="community">
          <tr>
            <th></th>
            <th>
              <img  className="basl_logo"
                src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1uladbr.png"
                loading="lazy"
              />
            </th>
            <th>
              MBA <br /> (beyond top 20)
            </th>
            <th>Online Business Courses / Degrees</th>
          </tr>
          <tr>
            <td>Peer Group</td>
            <td>
              Smart peers
              <br /> Acceptance rate is 10%
            </td>
            <td className="desk">Smart peers who’ve <br/>cleared CAT</td>
            <td className="mob" style={{padding: 0}}>Smart peers who’ve<br/> cleared CAT</td>
            <td className="desk">
              Anyone can enroll
              <br /> (no test)
            </td>
            <td className="mob">
              Anyone can <br />enroll
              (no test)
            </td>
          </tr>
          <tr>
            <td>Network</td>
            <td>
              Strong community of all
              <br /> B-Schools & Launchpad students
            </td>
            <td>Low-Medium</td>
            <td>Low</td>
          </tr>
          <tr>
            <td>Mentorship</td>
            <td className="borderBtm">
              Strong network across
              <br /> top companies
            </td>
            <td>Low-Medium</td>
            <td>Low</td>
          </tr>
        </table>
      )}
    </div>
  );
};

export default BenifitsTable;
