import React from "react";
import "./styles.scss";

const index = () => {
  return (
    <div className="basl-sales">
      <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ulx1ld.png" loading='lazy' className='pc-sales-dots-right' />
      <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ulza3r.png" loading='lazy' className='pc-sales-dots-left' />
      <div className="text-content">
        <div className="sales-sub-header">Why Sales?</div>
        <h2 className="sales-header">Sales - A career of <br className="pc-hide"/>champions</h2>
        <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm28kqanp.png" loading="lazy" className="salesUndeline" />
      </div>
      <div className="sales-desc desk">
      Sales is arguably the most important and unchanging skill in the world. Hear it from the experts:
      </div>
      <div className="sales-card-container-2">
        <div className="sales-card-container">
          <div className="sales-card gaurav">
            <a href="https://www.linkedin.com/in/gauravrastogi/" target="_blank">
              <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1uklm9s.png" loading="lazy" className="salesImage" />
              <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1uknpuh.png" loading="lazy" className="salesAlt" />
            </a>
          </div>
          <div className="sales-card fahad">
            <a href="https://www.linkedin.com/in/fahad-mistry/" target="_blank">
              <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ugv41l.png" loading="lazy" className="salesImage" />
              <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1uia90p.png" loading="lazy" className="salesAlt" />
            </a>
          </div>
          <div className="sales-card amit">
            <a href="https://www.linkedin.com/in/amit-agarwal-0103643/" target="_blank">
              <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ueckfh.png" loading="lazy" className="salesImage" />
              <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1uefw6p.png" loading="lazy" className="salesAlt" />
            </a>
          </div>
          <div className="sales-card varun">
            <a href="https://www.linkedin.com/in/varunsatia/" target="_blank">
              <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1umkkwj.png" loading="lazy" className="salesImage" />
              <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1umvd2s.png" loading="lazy" className="salesAlt" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default index;
