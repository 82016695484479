import React from 'react';
import "./styles.scss";
import Marquee from 'react-fast-marquee';



const index = () => {
  return (
    <div className='cohotM'>
      <div className="cohotM-head">Lifelong relationships with your cohort. <br/>
        Open access to events across the country</div>

      <Marquee  gradient={false}
        speed={67}
        pauseOnHover={false}
        className="miner-community"
        loop={0} >
        <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1u7o9zu.png" loading='lazy' className='marqueImg'/>
        <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1u7pvrp.png" loading='lazy' className='marqueImg'/>
        <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1u7r6iv.png" loading='lazy' className='marqueImg'/>
        <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1u7w5m1.png" loading='lazy' className='marqueImg'/>
        <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1u8151m.png" loading='lazy' className='marqueImg'/>
        <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1u8f99g.png" loading='lazy' className='marqueImg'/>
        <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1u8hr34.png" loading='lazy' className='marqueImg'/>
        <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1u8jqpt.png" loading='lazy' className='marqueImg'/>
        <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1u8n0x8.png" loading='lazy' className='marqueImg'/>
        <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1u8quj6.png" loading='lazy' className='marqueImg'/>
      </Marquee>
    </div>
  );
};

export default index;
