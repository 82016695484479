import React from 'react';
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const overViewCard = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  return (
    <div className="overview-card" ref={ref}>
      <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ue1kp5.png" loading="lazy" />
      {inView && (
        <>
          <motion.div
            variants={{
              hidden: { opacity: 0, y: -200, x: 60 },
              visible: { opacity: 1, y: -270, x: 60 },
            }}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.55, delay: 0.25 }}
            className="card-desc"
          >
          I was very confused whether I should go for an MBA - it would
          have taken at least 3 years before I started earning, including
          CAT prep. And if i didn't get an IIM, then ROI is not that good
          anyways.
            <br /> As opposed to that, Kraftshala was offering{" "}
            <b className='card-desc-mob'>much better ROI on my time and money</b>. I also had trust on
          Kraftshala since 2 of my friends were already placed in great
          brands.
            <br /> I am so glad i took the decision as I am now placed at
          Nestlé which was a dream company for me!
          </motion.div>
          <motion.div
            variants={{
              hidden: { opacity: 0, y: -200, x: 570 },
              visible: { opacity: 1, y: -286, x: 570 },
            }}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.75, delay: 0.7 }}
            className="profile-sec"
          >
            <div className="profile-name">
            Abhishek Nimawat <LinkedInIcon />
            </div>
            <div className="sub-profile">
            Sales Executive trainee, Nestlé
              <div className="light">Ex-Biotech graduate</div>
            </div>
          </motion.div>
        </>
      )}

    </div>
  );
};

export default overViewCard;
