import React from "react";
import "./styles.scss";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";


const GraphsMob = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  return (
    <div className="graphs pc-flex-none" ref={ref}>
      {inView && <>
        <motion.img
          variants={{
            hidden: { opacity: 0, y: 70 },
            visible: { opacity: 1, y: 0 },
          }}
          initial="hidden"
          animate="visible"
          transition={{ duration: 0.55, delay: 0.55 }}
          src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ude6kp.png"

          className="graph-img"
        />
        <motion.img
          variants={{
            hidden: { opacity: 0, y: 20 },
            visible: { opacity: 1, y: -30 },
          }}
          initial="hidden"
          animate="visible"
          transition={{ duration: 0.55, delay: 0.25 }}
          src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1udoybp.png"
          className="graph-img"
        />
        <motion.img
          variants={{
            hidden: { opacity: 0, y: 70 },
            visible: { opacity: 1, y: 0 },
          }}
          initial="hidden"
          animate="visible"
          transition={{ duration: 0.6, delay: 0.65 }}
          src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1udrbx0.png"
          className="graph-img"
        />
        <motion.img
          variants={{
            hidden: { opacity: 0, y: 30 },
            visible: { opacity: 1, y: -45 },
          }}
          initial="hidden"
          animate="visible"
          transition={{ duration: 0.6, delay: 0.3 }}
          src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1udvjin.png"

          className="graph-img"
        />
      </> }
    </div>
  );
};

export default GraphsMob;
