import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const OverViewMobile = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  return (
    <div className="overviewMob" ref={ref}>
      { inView && <>
        <motion.div
          variants={{
            hidden: { opacity: 0, y: 120, x: 32 },
            visible: { opacity: 1, y: 59, x: 32 },
          }}
          initial="hidden"
          animate="visible"
          transition={{ duration: 0.55, delay: 0.25 }}
          className="card-desc-mob"
        >
          Kraftshala was offering <b>much better ROI on my time and money as
          compared to any MBA beyond tier-1 colleges</b>. I am so glad i took the
          decision as I am now placed at Nestlé which was a dream company for
          me!
        </motion.div>
        <motion.div
          variants={{
            hidden: { opacity: 0, y: 160, x: 32, w: 200 },
            visible: { opacity: 1, y: 95, x: 32, w :200 },
          }}
          initial="hidden"
          animate="visible"
          transition={{ duration: 0.75, delay: 0.7 }}
          className="profile-sec"
        >
          <div className="profile-name">
            Abhishek Nimawat 
          </div>
          <div className="sub-profile">
            Sales Executive trainee, Nestlé
            <div className="light">Ex-Biotech graduate</div>
            <LinkedInIcon fontSize="large" />
          </div>
        </motion.div>
        
      </>}
    </div>
  );
};

export default OverViewMobile;
