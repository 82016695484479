import React from "react";
import "./styles.scss";

const AboutProgram = () => {
  return (
    <>
      <div id="about-bsl-program">
        <div className="text-content">
          
          <h2 className="about-program-header desk">
           In the quest to become a CEO, people with a sales background<br />
          have a solid advantage as you are doing part of the same work from the get go!
          </h2>
          <h2 className="about-program-header mob">
           In the quest to become a CEO, people with a sales background <br/>
          have a solid advantage as you are doing the same work from the get go!
          </h2>
          
        </div>
        <img
          className="about-program-img"
          src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ug4kvm.png"
          width={"100%"}
          loading="lazy"
        />
        <img
          className="about-program-img-mb"
          src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1um0wmq.png"

          width={"100%"}
          loading="lazy"
        />
      </div>
    </>
  );
};

export default AboutProgram;
