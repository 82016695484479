import React from "react";
import "./styles.scss";

import { StaticImage } from "gatsby-plugin-image";

const HeroSection = (props) => {
  const {
    name,
    phone,
    email,
    existingEmail,
    isCreated,
    counselorName,
    counselorPhone,
  } = props.formfields ?? {};

 

  return (
    <>
      <div className="hero-sec-container">
        <StaticImage
          className="ksBird"
          src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm28lqpot.png"
        />
        <div className="pgp-pc-basl">PGP in Sales and Business Leadership</div>

        <div className="trademark">
          with <b>INDUSTRY</b>Creds™ Certification
        </div>
        <div className="trademark-mob-basl">
          with <b>INDUSTRY</b>Creds™ Certification
        </div>

        <div className="hero-sub-title-basl">
          <div className="hash1">#1</div>{" "}
          <h1>
            Sales & Business School for
            <br />
            top-tier FMCG & B2B SaaS roles
          </h1>
        </div>

        <div className="ticks">
          <div className="tick">
            <StaticImage
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2a7uey9.png"
              loading="lazy"
              placeholder="blurred"
              className="mobile-none"
            />
            <StaticImage
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2a7uey9.png"
              loading="lazy"
              placeholder="blurred"
              className="pc-none"
            />
            Placements: Highest ₹17.2L | Average ₹10.5L
          </div>
          <div className="tick">
            <StaticImage
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2a7uey9.png"
              loading="lazy"
              placeholder="blurred"
              className="mobile-none"
            />
            <StaticImage
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2a7uey9.png"
              loading="lazy"
              placeholder="blurred"
              className="pc-none"
            />
            <span className="mobile-none">
              Recruiters include Nestlé, Leadsquared, Turbohire & more
            </span>
            <span className="pc-none">
              Recruiters include Nestlé, Leadsquared, Turbohire
            </span>
          </div>
        </div>

        <div className="more-info-basl">
          <div className="partners">
            <p className="partners-lable">6,000+ students trained</p>
            <div className="partners-images">
              <img
                src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1uemq7f.webp"

                alt="TurboHire"
                placeholder="none"
                loading="lazy"
                height={60}
                className="p"
              />
            </div>
          </div>
        </div>
      </div>
 
    </>
  );
};

export default HeroSection;
