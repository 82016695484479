import ajinkya from "../Assets/Ajinkya Choudekar.webp";
import anindya from "../Assets/Anindya Som Chaudhary.webp";
import Chinmaya from "../Assets/Chinmaya Panda.webp";
import Deepom from "../Assets/Deepon Das.webp";
import Gautham from "../Assets/Gautham CK.webp";
import Niharika from "../Assets/Niharika Bhatnagar.webp";
import Rohan from "../Assets/Rohan Rawat.webp";
import Shubhanan from "../Assets/Shubhanan Nath.webp";
import Vagisha from "../Assets/Vagisha Sharma.webp";

export const placementsData = [
  {
    // video: "https://www.youtube.com/embed/u9Kbbljls2s",
    image: Niharika,
    alt: "Niharika Bhatnagar",
    name: "Niharika Bhatnagar",
    batch: 2021,
    company: "Brand Strategy and Comms, Ola Campus",
    uni: "SIIB",
    //specialisation: "Paid Ads",
    // jobRole: "Brand Strategy and Comms",
    // profile: "https://www.linkedin.com/in/bhuwan-sharma-35578918b",
    testimonial: `I had the opportunity to be a part of Kraftshala's course and the experience has left me so much wiser. There was so much to learn, so much to absorb, and such eye opening perspectives which I would've never come across in my academic life. This course gave me everything that I needed in one place - from resume building to live projects to interview prep. I am looking forward to my professional life with renewed vigor because I know now I am well equipped to face any challenge.`,
  },
  {
    // video:"https://www.youtube.com/embed/R9NwWCjAJ-M",
    image: Rohan,
    alt: "Kraftshala Digital Marketing Student_Nilta",
    name: "Rohan Rawat",
    batch: 2021,
    company: "Area Sales Manager, Mahindra and Mahindra",
    uni: "Fore School of Management",
    //specialisation: "Paid Ads",
    // jobRole: "CA Finalist | Graduate Trainee, Marketing",
    // profile: "https://www.linkedin.com/in/nilta-sood/",
    testimonial: `Kraftshala's live projects provided a chance to work on practical solutions for retail growth and category expansion, along with enabling me with the sales know-how to draw insights from the analysis.
    The interactions with industry experts gave me a well-rounded view into the daily functioning as well as strategic planning across various industries.
    `
  },
  {
    // video:"https://www.youtube.com/embed/DNnW_1ykkRs",
    image: Deepom,
    alt: "Kraftshala Digital Marketing Student_Ruchi",
    name: "Deepon Das",
    batch: 2021,
    company: "Area Business Manager, Bluestone",
    uni: "TAPMI ",
    //specialisation: "Paid Ads",
    // jobRole: "Trainee Analyst",
    // profile: "https://www.linkedin.com/in/ruchi15",
    testimonial: `Kraftshala has given me access to some amazing humans - as friends, guides/mentors and well-wishers. I did 2 programs with them during my BSchool. The takeaways transformed from just awareness and exposure to application-based learning and on-field assignments. The right content is put across in a manner that helps (not just in sales training oneself) but aids you in your overall career. To summarize, it is more than just a certification. It is a mutual exchange of experiences and they give you direction at essential junctures of your professional journey`
  },
  // {
  //   // video:"https://www.youtube.com/embed/pVVZM2eCMqU",
  //   image: "",
  //   alt: "Student_Yash Thakkar",
  //   name: "Yash Thakkar",
  //   batch: 2021,
  //   company: "Founder, AISOLO",
  //   uni: "SIMSREE",
  //   //specialisation: "Paid Ads",
  //   // jobRole: "AISOLO",
  //   // profile: "https://www.linkedin.com/in/praveen-pandey-903103173",
  //   testimonial: `Learning at Kraftshala has been a unique experience. As a part of the Sales Leadership program 
  //   I was responsible to step in the shoes of an Area Sales Manager and perform set tasks that an ASM is expected to 
  //   perform on a daily basis. The experience was enriching-  interacting with multiple stakeholders, 
  //   looking at the product offerings, learning the market, devising actionable pointers- all of these combined with seamless and well guided mentorships, 
  //   made the program a journey. I would go on to say that the love I have for sales and marketing right now, is a direct by-product of the program I undertook.
  //   `,
  // },
  {
    // video:"https://www.youtube.com/embed/uXD3FpLqoQY",
    image: anindya,
    alt: "Kraftshala Digital Marketing Student_Vishal",
    name: "Anindya Som Chaudhary",
    batch: 2021,
    company: "Market Research Specialist, Gartner",
    uni: "Fore School of Management",
    //specialisation: "Paid Ads",
    // jobRole: "Digital Intern",
    // profile: "https://www.linkedin.com/in/vishal-jaiprakash-nair-981a8714a",
    testimonial: `The entire journey of Sales Leadership is best described by three Es: exhaustive, enlightening and engaging. The course exhaustively covers concepts which are not always taught during the MBA curriculum and thus helps us get a broader lens to view context. We get perspectives from various accomplished industry stalwarts which helps to keep us grounded to reality. The projects helped gain a practical exposure by going to physical retail outlets, learning how to talk to customers and derive quality insights, and we got in-depth feedback which helped develop our understanding even better. In totality, the course, the Kraft community has helped me learn concepts, build a strong network, land internships, win case study competitions and thus, excel till now.`,
  },
  {
    image: Chinmaya,
    alt: "Kraftshala Digital Marketing Student_Vishal",
    name: "Chinmay Panday",
    batch: 2021,
    company: "Global Route to Market Strategy, HUL",
    uni: "JBIMS ",
    //specialisation: "Paid Ads",
    // jobRole: "Digital Intern",
    // profile: "https://www.linkedin.com/in/vishal-jaiprakash-nair-981a8714a",
    testimonial: `I cannot narrow it down to a particular one thing about Kraftshala that helped me. Doing the projects with Sales Leadership and Brand Building programs gave me an understanding of how things work and gave me a headstart in understanding the processes and working my way through more productively.
    `
  },
  {
    image: ajinkya,
    alt: "",
    name: "Ajinkya Choudekar",
    batch: "",
    company: "Marketing manager, Classic Legends",
    uni: "JBIMS",
    testimonial: `Kraftshala joined me on my journey when I needed it the most. The course content is just half of the story of what I loved abt Kraftshala. The best thing was the support they provide in realizing our true potential. It could have been as easy as, u pay fees, do online course, submit deliverables, get report card and then tata bye bye. This isn't the case with Kraftshala. They've alloted me mentorship slots to help me with anything and everything right from CV review to mock interviews. That's just not it, they personally call up and check in. I mean who does this much? This really gained my respect for them as an organization And this is the exact reason why I consider Kraftshala as my true mentor.`
  },
  {
    image: Gautham,
    alt: "",
    name: "Gautham C K",
    batch: "",
    company: "ex-Area Sales Manager, Nestle",
    uni: "IIM Bangalore",
    testimonial: `The Sales Leadership programme at Kraftshala is an incredible platform to learn the nuances of sales. The course content along with the mentorship program from the experts in the field provides a great platform for marketing enthusiasts to rely on.`
  },
  {
    image: Shubhanan,
    alt: "",
    name: "Shubhanan Nath",
    batch: "",
    company: "Ops Strategy, Justdial; Ex-Sales, Tata Motors",
    uni: "IIM Kashipur",
    testimonial: `Kraftshala's sales leadership program is one of the most unique opportunities any sales and marketing enthusiast could take up. Seldom do we get to work on projects that give us hands-on experience on how retailing actually works. This experience has tremendously helped me over my last 2 years in multiple ways. I was also able to apply much of my learnings in my internship with Tata Motors where I was able to bag a PPO.`
  },
  {
    image: Vagisha,
    alt: "",
    name: "Vagisha Sharma",
    batch: "",
    company: "ex-Area Sales Manager, Nestle",
    uni: "IIM Lucknow ",
    testimonial: `
    I enrolled for Kraftshala's program and what really excited me was that the course kept me on my toes throughout. There were specific guidelines & feedback from industry professionals for the project which made the whole process enjoyable. If I needed help from a person from a specific company, I could easily find that on the kraftshala mentorship platform. When I got the Nestle offer through my INDUSTRYCreds score and the subsequent interview, I was overjoyed. Thanks to the Kraftshala family, who has stood with me throughout my MBA.
    `
  }
];
