

export const data = [
  {
    project: 'Project 1:',
    title: 'FMCG Sales with Nestlé',
    duration:  '100 Hours',
    desc: `An immersion into a Sales Managers’ role.<br/> Get mapped to a Nestlé manager and work on the regional priorities to drive business for Nestlé products.`,
    disclaimer: '',
    color: '#58BDF0',
    hColor: '#58BDF0',
    bg: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1u9b689.png",
    icon: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1u99fgn.png",
    mobImg: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1u9cyed.png"
    ,
    highlight: "FMCG Showcase with Nestlé Leaders",
    points: ["Kick-off call with company","Get mapped in teams","Field work","Office hours  with Kraftshala experts and mentors","Personalized feedback"]
  },
  {
    project: 'Project 2:',
    title: 'B2B Sales with Turbohire*',
    duration:  '100 Hours',
    desc: `An insight into the entire B2B Sales Process. Here you will understand the different buyer personas, generate leads by researching and reaching out to potential business customers`,
    disclaimer: '* Companies change every batch',
    color: '#D86136',
    hColor: '#E38C54',
    bg: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1u9qy1t.png",
    icon: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1u9kfcn.png"
    ,
    mobImg: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1u9sfkx.png",
    highlight: "B2B Showcase with Turbohire Leaders",
    points: ["Kick-off call with company","Product / User Understanding","Prospecting","Lead Generation / Qualification","Pitch Deck"]
  },
  {
    project: 'Project 3:',
    title: 'Practice School @ Workplace',
    duration:  'Can vary depending on company',
    desc: `Practice what you’ve learnt by getting into internships / traineeships in FMCG and B2B Sales, in marquee organizations. 
    <br/><br/>
    This helps you gain a rounded experience, and depending on your performance, you can secure a PPO as well.`,
    color: '#f1ae13',
    hColor: '#f1ae13',
    bg: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1u9vb1y.png",
    icon: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1u9u53a.png",
    mobImg: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1u9y0nx.png",
    highlight: " PPO Conversion",
    points: ["Practice School Company Applications","Clearing Selection process","Start your work","Check-ins with Kraftshala mentor","Final presentation"]
  },
  
  
];