import React from "react";
import { useState } from "react";
import Accordion from "./Accordion/accordion";
import { faqsData } from "./data";
import "./styles.scss";

function FAQ() {
  const [show, setShow] = useState();
  const [selected,setSelected] = useState('general');
  let filterData = faqsData.filter(el => {
    return selected.includes(el.category);
  });

  if(selected === 'general') {
    filterData = faqsData;
  }
  
  return (
    <div className="basl-main-faq faq-container">
      <h2 className="csm-faq-title">PGP in Sales and Business Leadership - FAQs<br/>
        <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2aafvlc.png" className="basl-faq-underline mobile-none" loading="lazy" />
        <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1yp0pls.png" className="basl-faq-underline pc-none" loading="lazy" />
      </h2>
      <div className="faq-content">
        <div className="list-container">
          <div className={`list-item  ${selected.includes("general") && "selected"}`} onClick={() => setSelected('general')}>All</div>
          <div className={`list-item  ${selected.includes("eligibility") && "selected"}`} onClick={() => setSelected("eligibility")}>Eligibility</div>
          <div className={`list-item  ${selected.includes("admission") && "selected"}`} onClick={() => setSelected("admission")} >Admission Process</div>
          <div className={`list-item  ${selected.includes("outcome") && "selected"}`} onClick={() => setSelected("outcome")}>Outcome</div>
        </div>
        <div className="faqs mlp-faq">
          {filterData.map((info, k) => (
            <Accordion key={k} index={k} show={show} setShow={setShow} info={info} selected={selected} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default FAQ;
