

export const scholarships = [
  {
    id: 1,
    img: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1umap92.png",
    head: "Women in Sales Scholarship",
    detail:
      "For women who are not afraid to challenge gender disparity in Sales",
  },
  {
    id: 2,
    img: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1umcbs6.png"
    ,
    head: "Entrepreneurial Ambition Scholarship",
    detail: "For candidates who want to build skills for their business ambition",
  },
  {
    id: 3,
    img: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ume8vk.png",
    head: "Sales Excellence Scholarship",
    detail: "For candidates with relevant experience in Sales",
  },
  {
    id: 4,
    img:"https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1umga0e.png",
    head: "Need Based Scholarship",
    detail:
      "For candidates with a household income < 8L per annums",
  },

  {
    id: 5,
    img: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1umfb1u.png"
    ,
    head: "Merit Based Scholarship",
    detail:
      "For candidates with exceptional academic scores/ professional achievements ",
  },
  {
    id: 6,
    img: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1umhnft.png"
    ,
    head: "Standout Performance Scholarship",
    detail: " For candidates who’ve done exceptionally well in the admission process",
  },
];
