

export const MentorData = [
  {
    head: "Strategic Business Pillars",
    card: [
      {
        image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ub9bxb.png",
        name: "Hari Hara",
        position: "ex-HR Business Partner, ",
        company: "Amazon",
        place: "XLRI 2011",
      },
      {
        image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ubbvk9.png"
        ,
        name: "Harsh Kumar",
        position: "Senior DGM,",
        company: "NCRTC",
        place: "FMS 2010",
      },
      {
        image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1uazq3k.png",
        name: "Eshu Sharma",
        position: "Co-Founder,",
        company: "Kraftshala, ex-HUL",
        place: "FMS 2015",
      },
      {
        image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ubuy6t.png"
        ,
        name: "Varun Sethi",
        position: "Management Consultant,",
        company: "Accenture; Ex- BCG",
        place: "IIM K 2020",
      },
      {
        image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ual6rx.png",
        name: "Ayushi Mona",
        position: "Director Marketing @",
        company: "Delhivery",
        place: "MICA 2018",
      },
      {
        image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ubw9hb.png"
        ,
        name: "Sashank Vissa",
        position: "Senior Consultant @",
        company: "IBM",
        place: "IIM Indore 2021",
      },
    ],
  },{
    head: "B2B SaaS Sales",
    card: [{
      image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ub45m7.png",
      name: "Fahad Mistry",
      position: "VP, Sales and Strategy,",
      company: "Turbohire",
      place: "Ex-BlueOceans",
    },{
      image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ubdr83.png"
      ,
      name: "Himanshu Sharma",
      position: "Sales Director",
      company: "Darwinbox",
      place: "IIM A 2017",
    },{
      image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1uaabys.png",
      name: "Archit Kashyap",
      position: "Sales, Clevertap",
      company: "ex-WebEngage",
      place: "MDI 2016",
    },{
      image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ubx534.png"
      ,
      name: "Shazan Baqri",
      position: "Demand Gen and",
      company: "Partnerships @ Turbohire",
      place: "Ex-Abentra",
    },{
      image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ubpd9h.png",
      name: "Pritika Khorana",
      position: "AVP Sales, Konnect",
      company: "Ex-Happay",
      place: "",
    },{
      image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1uacumi.png",
      name: "Aman Saxena",
      position: "Chief Manager,",
      company: "Business Standard",
      place: "NMIMS 2016",
    },{
      image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1uc1hiq.png"
      ,
      name: "Tanvi Butalia",
      position: "Senior Account Manager",
      company: "iZooto, Ex-Harappa,",
      place: "LeverageEdu",
    },{
      image:"https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1uc6g69.png"
      ,
      name: "Tarun Kumar",
      position: "Director - Sales @",
      company: "TurboHire",
      place: "ex-InFreedo, Linkedin",
    }]
  },{
    head: "FMCG Sales",
    card: [
      {
        image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ua96t6.png",
        name: "Abhijit Arora",
        position: "Ex-Minimalist | Ex-",
        company: "L'Oreal",
        place: "IIM L 2009"
      },{
        image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ubz02m.png"
        ,
        name: "Sourav Choudhury",
        position: "Prof, SPJAIN and",
        company: "IIM Mumbai",
        place: "Ex-Director, McCormick"
      },{
        image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1uascow.png",
        name: "Eshan Sett",
        position: "Ex-National Manager,",
        company: "Marico",
        place: "FMS 2014"
      },{
        image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ubsnw8.png"
        ,
        name: "Sanjog Singh Ahuja",
        position: "Customer Marketing",
        company: "Manager, Mars Wrigley",
        place: "Ex- Nestlé"
      },{
        image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ubfd5c.png",
        name: "Kaustubh Tripathi",
        position: "Growth, Reliance Retail",
        company: "Ex- ITC Sales",
        place: ""
      },{
        image: "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y1k9ym2en2qzm.png"
        ,
        name: "Vagisha Sharma",
        position: "Brand Manager, Sunrise,",
        company: "Ex-Sales",
        place: "IIM L 2019"
      }
    ]
  },{
    head: 'BPS and Human Skills',
    card: [{
      image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1uca22j.png"
      ,
      name: "Varun Satia",
      position: "Founder, Kraftshala",
      company: "ex-MAGGI India",
      place: "FMS 2011"
    },{
      image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1uae2eb.png",
      name: "Anushka Kapur",
      position: "Program Lead, Kraftshala",
      company: "Ashoka 2018",
      place: ""
    },{
      image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ubrh9v.png"
      ,
      name: "Roshini Maria",
      position: "Human Skills, Kraftshala",
      company: "ex-Teach For India 2019",
      place: ""
    },{
      image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1uaosd9.png",
      name: "Darshan",
      position: "Human Skills, Kraftshala",
      company: "ex-The/Nudge Institute",
    }]
  }
];
