import React, { useEffect, useState } from "react";
import Seo from "../../components/Seo/seo";
import Layout from "../../components/Layout/layout";
import NavBar from "../../components/NavBar/navbar";
import Overview from "../../components/BuisnessSalesLeadership/Overview/overview";
import Benefits from "../../components/BuisnessSalesLeadership/Benefits/benefits";
import AdmissionProcess from "../../components/BuisnessSalesLeadership/AdmissionProcess/admissionProcess";
import Learning from "../../components/BuisnessSalesLeadership/Learning";
import Faq from "../../components/BuisnessSalesLeadership/FAQS/faq";
import Floater from "../../components/BuisnessSalesLeadership/Floater/Floater";
import PlacementsMain from "../../components/BuisnessSalesLeadership/Placements";
import Roles from "../../components/BuisnessSalesLeadership/Roles/Roles";
import Tools from "../../components/BuisnessSalesLeadership/Tools/Tools";
import Outcomes from "../../components/BuisnessSalesLeadership/Outcomes/Outcomes";
import AlumniCarousal from "../../components/BuisnessSalesLeadership/Alumni";
import CohotMasanory from "../../components/BuisnessSalesLeadership/CohotMasonary";
import KsJourney from "../../components/BuisnessSalesLeadership/KsJourney";
// import WhatsappWidget from "../../components/WhatsappWidget";
import Trainers from "../../components/BuisnessSalesLeadership/Trainers/trainers";
// import Fee from "../../components/MLPMainComp/Fee/fee";
import "./styles.scss";
import CareerPrep from "../../components/BuisnessSalesLeadership/CareerPrep";
import AboutProgram from "../../components/BuisnessSalesLeadership/AboutProgram";
import Sales from "../../components/BuisnessSalesLeadership/Sales";
import Fee from "../../components/BuisnessSalesLeadership/Fee";
import NewsCarousal from "../../components/BuisnessSalesLeadership/News";
import Scholarship from "../../components/BuisnessSalesLeadership/Scholarship";
import HeroSectionMb from "../../components/BuisnessSalesLeadership/HeroSection/heroSectionMb";
import BASLForm from "../../components/Forms/BASLForm";
import { useLocation } from "@reach/router";
import IvrSection from "../../components/BuisnessSalesLeadership/Ivr/index";
import OnGroundProject from "../../components/BuisnessSalesLeadership/OnGroundProject";
import Marquee from "react-fast-marquee";
import HeroSection from "../../components/BuisnessSalesLeadership/HeroSection/heroSection";

const BusinessSalesTrainingProgram = (props) => {
  const location = useLocation();
  const navBarContent = [
    {
      name: "Benefits",
      id: "benefits",
    },
    {
      name: "Learning",
      id: "learning",
    },
    {
      name: "Projects ",
      id: "projects",
    },
    {
      name: "Outcomes",
      id: "outcomes",
    },
    {
      name: "Experts",
      id: "experts",
    },
    {
      name: "Curriculum",
      id: "curriculum",
    },
    {
      name: "Admission",
      id: "admission",
    },
    {
      name: "Apply Now",
      id: "HeroSection",
      //formLink: true,
    },
  ];

  const [isHidden, setIsHidden] = useState(true);

  useEffect(() => {
    function handleScroll() {
      const currentScrollPosition = window.pageYOffset;

      setIsHidden(currentScrollPosition < 800); // Change 100 to the number of pixels after which you want to hide the element
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  console.log(location?.state?.formFields?.form_submitted ,"forsubmittee");
  console.log('location.state:', location.state);

  return (
    <React.Fragment>
      <Seo
        title="India First Online Sales Course With Job Placement Upto 17 LPA"
        description="Join India's best online sales course with placements of ₹9-17 LPA. 8 months part-time sales program to launch your sales leadership career. Enroll Now!"
        url="https://www.kraftshala.com/sales-course/"
      />

      <div id="HeroSection" className="main-mlp-page basl-page">
        <Marquee
          gradient={false}
          speed={40}
          pauseOnHover={true}
          className="marquee-container-header"
          loop={0}
        >
          <i className="marquee-item">
            {" "}
            * Applications for Batch of 2024 are closed now *{" "}
          </i>{" "}
          &nbsp;
          <i> Early Applications open for Batch of 2025 * </i>
        </Marquee>
        <Layout formLink="/marketing-launchpad/digital-marketing-course/">
          <div className="hero-div basl-hero-div" id="hero-div">
            {!location?.state?.formFields && !location?.state?.formFields?.form_submitted && (
              <HeroSection
                urlParams={location?.search}
                formfields={location?.state?.formFields}
              />
            )}
            <BASLForm
              search={location?.search}
              pathname={location?.pathname}
              locState={location?.state}
            >
              {/* <ThankYou
                search={location?.search}
                formfields={location?.state?.formFields}
                campaignId={location?.state?.campaignId}
              /> */}
            </BASLForm>
            <HeroSectionMb />
          </div>
          {/* <GoTo /> */}
          <div className="component-center">
            <Floater />
          </div>
          <div className="basl-main-overview bg-mob-size">
            <NavBar
              content={navBarContent}
              offset={902}
              applyLink={`/marketing-launchpad/digital-marketing-course/${location?.search}`}
            />

            <div id="benefits" className="section">
              <Overview />
            </div>
            <Sales />
            <AboutProgram />

            {/* <div id="benefits" className="section"> */}
            <Benefits />
            {/* </div> */}

            <div className={isHidden ? "fixed-bottomHidden" : "fixed-bottom"}>
              {!isHidden && (
                <>
                  {" "}
                  <button
                    className={"footer-btn"}
                    onClick={() => {
                      document.querySelector("#hero-div").scrollIntoView({
                        behavior: "smooth",
                        block: "end",
                        inline: "nearest",
                      });
                    }}
                  >
                    Apply now
                  </button>
                </>
              )}
            </div>
            <div id="learning" className="section">
              <PlacementsMain />
              <Roles />
            </div>
            <div className="section">
              <Learning />
            </div>
            <div id="projects" className="section">
              <OnGroundProject />
            </div>
            <Tools />
            <div id="outcomes" className="section">
              <Outcomes />
            </div>

            <div id="trainers" className="section">
              <Trainers />
            </div>

            <div id="admission-process" className="section">
              <AdmissionProcess urlParams={location?.search} />
            </div>

            <CohotMasanory urlParams={location?.search} />
            <div id="curriculum" className="section">
              <KsJourney />
            </div>

            <div className="section">
              <CareerPrep />
            </div>

            <div id="fee" className="section">
              <Fee />
            </div>

            <div id="fee" className="section">
              <Scholarship />
            </div>

            <div id="fee" className="section">
              {/* <SalesForm /> */}
            </div>

            <div id="basl-alumni" className="section">
              <AlumniCarousal />
            </div>

            <NewsCarousal />

            <div id="sales-faq" className="section">
              <div className="faq">
                <Faq />
              </div>
            </div>
            <div id="Ivr" className="section">
              <IvrSection />
            </div>
          </div>
        </Layout>
      </div>
      {/* <WhatsappWidget link="https://wa.me/message/HHNAOZDIMB4ZE1" /> */}
    </React.Fragment>
  );
};

export default BusinessSalesTrainingProgram;
