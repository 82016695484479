import React from "react";
// import { Companies } from "./Companies";
import "./styles.scss";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

import GraphMobile from "./GraphsMob";

export const PlacementsMain = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  return (
    <div className="bsl-main-pillars">
      <div className="bslp-student-slider">
        <div className="placement-sub-title">
          Kraftshala’s PGP in<br className="pc-hide"/> Sales and Business Leadership
        </div>
        <h2 className="pillars-head">Built on 4 core pillars</h2>
        
        <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2aafvlc.png" className="placement-underline" />

        <div className="graphs mobile-flex-none" ref={ref}>
          {inView && <><motion.img
            variants={{
              hidden: { opacity: 0, y: 70 },
              visible: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.45, delay: 0.25 }}
            src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ude6kp.png"

            className="graph-img"
          />
          <motion.img
            variants={{
              hidden: { opacity: 0, y: 70 },
              visible: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.55, delay: 0.45 }}
            src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1udoybp.png"
            className="graph-img"
          />
          <motion.img
            variants={{
              hidden: { opacity: 0, y: 70 },
              visible: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.70, delay: 0.65 }}
            src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1udrbx0.png"
            className="graph-img"
          />
          <motion.img
            variants={{
              hidden: { opacity: 0, y: 70 },
              visible: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.80, delay: 0.75 }}
            src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1udvjin.png"
            className="graph-img"
          />
          </>}
        </div>
      
        <GraphMobile />
      </div>
    </div>
  );
};

export default PlacementsMain;
