// import { StaticImage } from 'gatsby-plugin-image';
import React from "react";
import "./styles.scss";



const Roles = () => {
  const rolesImg = ["https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1yo74gh.png"
    , "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1yo8c9s.png"
    , "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1yoa5cr.png"
  ];

  const settings = {
    dots: false,
    infinite: false,
    speed: 1000,
    autoplaySpeed: 4000,
    fadeIn: false,
    autoplay: false,
    pauseOnHover: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 805,
        settings: {
          slidesToShow: 1,
          variableWidth: false,
          // centerMode: true,
        },
      },
    ],
  };

  return (
    <div className="basl-marketing-roles">
      <div className="role-content">
        <div className="basl-role-title">
        Kraft <span className="kra">/krɑːft/ <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1umj58i.png" className="speaker" loading="lazy" /></span>
        </div>
        <div className="noun">noun</div>
        <div className="basl-role-desc">Krafts are skills that help you create value for the world and exponential career growth for yourself. You’ll learn frameworks, gain insights, apply them on business problems and practice them relentlessly to achieve an in-depth mastery of the skill.</div>
      </div>
      <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1uff3pv.png" className="bookmark" loading="lazy" />
    </div>
  );
};

export default Roles;
