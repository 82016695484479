import React from "react";
import "./styles.scss";
import CardMob from "./CardMob";

const OnGroundProject = () => {
  return (
    <section className="basl-on-ground-project">
      <div className="index-bg">02</div>
      <h2 className="ground-project-header">
        Business Generating /<br className="pc-hide" /> Field Projects 
        <br className="mobile-hide" />
        with Industry’s Top Brands
      </h2>
      <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2aafvlc.png" className="ground-project-underline" />

      <CardMob />
    </section>
  );
};

export default OnGroundProject;
