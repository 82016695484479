export const cardData = [
  {
    term: 1,
    index: 1,
    head: "Case-Based Learning",
    bg: '#FBE7B9',
    desc: [
      "Commercial functions",
      "SaaS revenue models, Metrics, Pricing models",
    ],
  },
  {
    term: 1,
    index: 2,
    bg: '#DFF4F8',
    head: "Business-driving projects",
    desc: [
      "Case Competition -  Growth @ Startup",
      "Business Case Showcase",
      "Creating work products like consultants",
    ],
  },
  {
    term: 1,
    index: 3,
    head: "Workshops",
    bg: '#F4D0C3',
    desc: [
      "Kraftshala’s DIG DEEP framework",
      "Crafting your stories & profiles",
      "Leveraging AI for profile building",
    ],
  },
  {
    term: 2,
    index: 1,
    head: "Case-Based Learning",
    bg: '#FBE7B9',
    desc: [
      "Core Persuasion & Story-telling",
      "Building ICPs and User persona",
      "FMCG Ecosystem, Revenue models and Channel Growth Strategies",
      "Linkedin Sales Nav for Research",
    ],
  },
  {
    term: 2,
    index: 2,
    head: "Business-driving projects",
    bg: '#DFF4F8',
    desc: [
      "Building Persuasion @ TryKiya* - 75 hours",
      "Impactful communication",
    ],
    disc: "* Companies change every batch",
  },
  {
    term: 2,
    index: 3,
    bg: '#F4D0C3',
    head: "Workshops",
    desc: ["Problem finding", "Active listening"],
  },
  {
    term: 2,
    index: 4,
    bg: '#E2FBF6',
    head: "1-1 interviews",
    desc: ["Behavioral Mock Interview + Roadmap on improvements"],
  },
  {
    term: 3,
    index: 1,
    bg: '#FBE7B9',
    head: "Case-Based Learning",
    desc: [
      "ROI and Cash flow management",
      "B2B Sales process - Prospecting, Lead Gen, Qualification, Negotiation and Objection handling",
      "Distributor Metrics, ROI and Credit Management",
      "Prompt Engineering for lead generation",
      "CRM training for Leadsqaured",
    ],
  },
  {
    term: 3,
    index: 2,
    bg: '#DFF4F8',
    head: "Business-driving projects",
    desc: [
      "Building Sales Pipeline @ Rentity* - 100 hours",
      "B2B SaaS Showcase",
      "Building & Optimizing Sales Funnels",
    ],
    disc: "* Companies change every batch ",
  },
  {
    term: 3,
    index: 3,
    bg: '#F4D0C3',
    head: "Workshops",
    desc: [
      "Transferable consulting frameworks for problem solving",
      "Building impact and convince",
    ],
  },
  {
    term: 3,
    index: 4,
    bg: '#E2FBF6',
    head: "1-1 interviews",
    desc: ["B2B Mock Interview + Roadmap on improvements"],
  },{
    term: 4,
    index: 1,
    bg: '#FBE7B9',
    head: "Case-Based Learning",
    desc: [
      "Stakeholder Management",
      "Account Based Marketing Strategy + Execution",
      "Shopper Engagement and Marketing",
      "Automate workflows and trackers using Zapier",
    ]
  },{
    term: 4,
    index: 2,
    bg: '#DFF4F8',
    head: "Business-driving projects",
    desc: [
      "Driving Growth on-field @ Nestlé-  100 hours",
      "FMCG Showcase",
      "Driving Growth using field insights",
    ],
    disc: "* Companies change every batch ",

  },
  {
    term: 4,
    index: 3,
    bg: '#F4D0C3',
    head: "Workshops",
    desc: [
      "Excel as an analysis tool",
      "Building a stronger work ethic",
    ],
  },
  {
    term: 4,
    index: 4,
    bg: '#E2FBF6',
    head: "1-1 interviews",
    desc: ["FMCG Mock Interview + Roadmap on improvements"],
  },{
    term: 5,
    index: 1,
    bg: '#FFF',
    head: "Practice School @ Workplace",
    desc: ["Gain real experience","Get a shot to crack a PPO at same company",'If not, your practice school experience helps you crack your job'],
  }
];
