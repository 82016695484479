import React from "react";
import "./styles.scss";



const HeroSectionMb = () => {
  return (
    <div className="basl-more-info-mb">
      <div className="students-train"> 6,000+ students trained</div>
      <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ueppo1.png" loading="lazy" />
    </div>
  );
};

export default HeroSectionMb;