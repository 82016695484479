

export const CommunityImages = [
  {
    image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1t8g1uv.png",
    alt: "KS Community Professional_nandu",
  },
  {
    image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1t91vdw.png",
    alt: "KS Community Professional_Sambit",
  },
  {
    image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1t8kp45.png",
    alt: "KS Community Professional_Priyan",
  },
  {
    image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1t8bvh5.png",
    alt: "KS Community Professional_Gaurav",
  },
  {
    image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1t93qlk.png",
    alt: "KS Community Professional_Sushrut",
  },
  {
    image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1t89vdt.png",
    alt: "KS Community Professional_Fahad",
  },
  {
    image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1t8hygh.png",
    alt: "KS Community Professional_Ninad",
  },
  {
    image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1t8daqn.png"
    ,
    alt: "KS Community Professional_Himanchu",
  },
  {
    image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1t95gk1.png",
    alt: "KS Community Professional_Vishal",
  },
  {
    image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1t90nz8.png",
    alt: "KS Community Professional_Ravi",
  },
  {
    image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1t872z2.png",
    alt: "KS Community Professional_Amit",
  },
 
];
