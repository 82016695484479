import React, { useEffect, useState } from "react";
import "./styles.scss";
import { MentorData } from "./data";

const Index = () => {
  const [mentorSelected, setMentorSelected] = useState(0);

  const handleScrollToSection = (id) => {
    const section = document.getElementById(`admission-${id}`);

    if (section) {
      window.scrollTo({
        top: section.offsetTop - 40,
        behavior: "smooth",
      });
    }

    setMentorSelected(id);
  };

  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.querySelector(".page-navbar");
      const sections = document.querySelectorAll(".a-c-cards-sec");

      sections.forEach((section, index) => {
        const sectionTop = section.offsetTop - 30;
        const sectionBottom = sectionTop + section.offsetHeight;
        const scrollPosition = window.scrollY + navbar.offsetHeight;

        if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
          setMentorSelected(index);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="Admission-process-basl">
      <h2 className="Admission-process-header desk">
        Mentorship / Everyday sessions with leaders in their <span className="a-p-yellowTxt">Kraft</span>
        <br />
      </h2>
      <h2 className="Admission-process-header mob">
        Mentorship / Everyday sessions <br /> with leaders in their <span className="a-p-yellowTxt">Kraft</span>
        <br />
      </h2>

      <div className="admisson-container mobile-flex-hide">
        <div id="learning-sticky-card" className="sticky-card">
          <div className="a-c-badges">
            {MentorData.map((mentor, index) => (
              <div
                key={index}
                className={`badge ${index === mentorSelected ? 'active-badge' : ''}`}
                onClick={() => handleScrollToSection(index)}
              >
                {mentor.head}
              </div>
            ))}
          </div>
        </div>
        <div className="a-c-cards">
          {MentorData.map((mentor, mentorIndex) => (
            <div key={mentorIndex} id={`admission-${mentorIndex}`} className="a-c-cards-sec">
              {mentor.card.map((mentorCard, cardIndex) => (
                <div key={cardIndex} className="a-c-card">
                  <img src={mentorCard.image} className="a-c-card-head" loading="lazy" />
                  <div className="a-c-card-footer">
                    <div className="footer-name">{mentorCard.name}</div>
                    <div className="footer-position">{mentorCard.position}</div>
                    <div className="footer-company">{mentorCard.company}</div>
                    <div className="footer-place">{mentorCard.place}</div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>

      <div className="admisson-container pc-flex-none">
        {MentorData.map((mentor, index) => (
          <div key={index} className="a-c-mob">
            <div className="badge">{mentor.head}</div>
            <div className="a-c-card-container">
              <div className={`a-c-cards card-${index}`}>
                {mentor.card.map((card, cardIndex) => (
                  <div key={cardIndex} className="a-c-card">
                    <img src={card.image} className="a-c-card-head" loading="lazy" />
                    <div className="a-c-card-footer">
                      <div className="footer-name">{card.name}</div>
                      <div className="footer-position">{card.position}</div>
                      <div className="footer-company">{card.company}</div>
                      <div className="footer-place">{card.place}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
        <div className="a-c-cards"></div>
      </div>
    </div>
  );
};

export default Index;
