

export const data = [{
  id: 0,
  title: "Strategic Business Pillars",
  desc: "You learn the fundamentals of commercial functions - marketing, sales and supply chain including User Understanding, Operations Management, Financial Intelligence (P&L and Cash Flow Management) - to help you understand how to drive overall business health",
  image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1t9xirf.png"

},{
  id: 1,
  title: "Sales and Persuasion Strategies",
  desc: "Learning how sales involves moving others. Master Kraftshala’s framework - The Sales Bridge, which includes activities & details on  building trust & connections, intelligence gathering, probing solving & persuading and objection handling.",
  image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1t9vyv4.png"

},{
  id: 2,
  title: "B2B Sales Excellence: SaaS",
  desc: "You master the end-to-end process of a B2B SAAS Sales cycle. From prospecting, lead generation & qualification, strategic research, cold calling and emailing, conducting demos, leveraging multiple tools and more. You learn all of it from cases and insights from some of India’s best practitioners.",
  image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1t9nmq5.png"

},{
  id: 3,
  title: "B2C Sales Excellence: FMCG",
  desc: "You get into the details of the fascinating FMCG (Fast Moving Consumer Goods) sector by learning about distribution structures, setting up RTM, Category Management (including Modern trade, Quick commerce, Ecommerce), Distributor Management, DS management, RoI and Credit Management, Merchandising, Inventory and bad goods management, Trade and Marketing plan, Building Effective Relationships, People Management, Effective reporting",
  image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1t9pf9y.png"

},{
  id: 4,
  title: "Better Problem Solving",
  desc: "View the world through the lens of problems to be solved. Using case studies to internalize Kraftshala’s DIG DEEP principles to solve problems rigorously, systematically and quickly, esp in your work context. Use Excel as a problem solving tool to enable data driven decision making.",
  image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1t9t4za.png"

},{
  id: 5,
  title: "Human Skills",
  desc: "Here we learn transferable skills which will form the basis of your success at your workplaces - impactful and empathetic communication, work product training, building a digital presence, active listening and building work ethics to drive behavior change and strong reflection skills",
  image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1t9rt4h.png"

}
];