import React from "react";
import { eligibilty } from "./eligibility";


const PlacementProcess = () => {
  return (
    <>
      <div className="career-title mtop-3">Placement process</div>
      <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2aafvlc.png" loading="lazy" className="placement-underline" />
      <img
        src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1uldyhq.png"
        loading="lazy"
        className="placement-prep-img mobile-hide"
      />
      <img
        src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ulfeix.png"
        loading="lazy"
        className="placement-prep-img pc-hide"
      />

      <img
        src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ul8hdr.png"
        loading="lazy"
        className="pc-placement-dots-right"
      />
      <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ul67cv.png" loading="lazy" className="pc-placement-dots-left" />
      <div className="career-title mtop-3 section" id="admission">
        Admission Process
      </div>
      <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2aafvlc.png" loading="lazy" className="placement-underline" />
      <div className="Admission-prep-desc">
        3-Step Process which takes up almost 7-10 days from application to the
        final decision.{" "}
      </div>
      <img
        src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ue88q7.png"
        loading="lazy"
        className="AdmissionImg-prep-img mobile-hide"
      />
      <img
        src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ueb1do.png"
        loading="lazy"
        className="AdmissionImg-prep-img pc-hide"
      />

      <div className="career-title mtop-3">Eligibility criteria</div>
      <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2aafvlc.png" loading="lazy" className="placement-underline" />
      <div className="basl-eligibility-cards">
        {eligibilty &&
          eligibilty.map((eligibility,index) => {
            return (
              <div key={index} className="basl-eligibility-card">
                <img src={eligibility.img} className="basl-eligi-icon" loading="lazy" />
                <div>
                  <div className="basl-eligibility-head">{eligibility.head}</div>
                  <div className="basl-eligibility-desc">{eligibility.desc}</div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default PlacementProcess;
